import { Play } from "assets/svg";
import React, { FC, useEffect, useState } from "react";
import styles from "./upcoming-event.module.scss";
import { DateTime } from "luxon";

type Props = {
  img?: string;
  title: string;
  tutor: string;
  date: string;
};

const UpcomingEvents: FC<Props> = ({ img, title, tutor, date }) => {
  const [formattedDate, setFormattedDate] = useState<DateTime>();

  useEffect(() => {
    setFormattedDate(DateTime.fromISO(date));
  }, [date]);

  return (
    <div className={styles.card}>
      <div className={styles.img}>
        <img src={img} className="nav-img large" alt="dyeka ungoing event" />
        <div className={styles.playIcon}>
          <Play style={{ height: "100%", width: "100%" }} />
        </div>
      </div>
      <div>
        <div className="mt-10 text-dark">
          <div>
            <b>{title}</b>
          </div>
          <div className="text-primary-color mt-5">
            <small>Teacher: {tutor}</small>
          </div>
          {formattedDate && (
            <div className="flex justify-content-between mt-10">
              <small className="text-dark">
                <b>{formattedDate.toFormat("LLL dd")}</b>
              </small>
              <small className="text-dark">
                <b>{formattedDate.toFormat("hh:mm a")}</b>
              </small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default UpcomingEvents;
