import { Constants } from "core/utils/constants";
import { SecureStorage } from "core/utils/storage";
import React, { FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

interface GuardRouteProps extends Omit<RouteProps, "component"> {
  component: React.ElementType;
}

const GuardRoute: FC<GuardRouteProps> = ({ component: Component, ...args }) => {
  const secureStorage = new SecureStorage();
  const isAuthenticated = secureStorage.getItem(Constants.token);
  const isCurrentUser = secureStorage.getItem(Constants.currentUser);

  const auth = () => {
    if (isAuthenticated && isCurrentUser) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Route
      {...args}
      render={(props) =>
        !auth() ? <Component {...props} /> : <Redirect to="/app/dashboard" />
      }
    />
  );
};
export default GuardRoute;
