import React, { FC } from "react";
import styles from "./chips.module.scss";

type Props = {
  item: string;
  active?: boolean;
  onClick?: () => void;
};

const Chips: FC<Props> = ({ item, active, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={[
        styles.badge,
        "text-center mb-15",
        active ? "chip-active" : "",
      ].join(" ")}
    >
      {item}
    </div>
  );
};

export default Chips;
