import { Constants } from "core/utils/constants";
import { SecureStorage } from "core/utils/storage";
import React, {
  createContext,
  FC,
  ReactChild,
  useEffect,
  useState,
} from "react";
import { IUser } from "types/user";
import { userAction } from "../core/actions";

const initState = {
  signUpState: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone_number: "",
  } as IUser,
  updateSignupState: (value: object) => {},
  currentUser: {} as IUser,
  temp: {} as IUser,
  updateCurrentUser: (value: IUser) => {},
  storeTemp: (value: IUser) => {},
  removeLoggedOutUser: () => {},
  removeTemp: () => {},
  removeSelectedProfile: () => {},
  refreshUser: () => {},
};

const secureStorage = new SecureStorage();
const UserContext = createContext(initState);
export const UserProvider = UserContext.Provider;

type Props = {
  children?: ReactChild | ReactChild[];
};

export const UserProviderContainer: FC<Props> = ({ children }) => {
  const [signUpState, setSignUpState] = useState<IUser>(initState.signUpState);
  const [currentUser, setCurrentUser] = useState<IUser>(initState.currentUser);
  const [temp, setTemp] = useState<IUser>(initState.temp);

  useEffect(() => {
    let storedUser = secureStorage.getItem(Constants.currentUser);
    if (typeof storedUser === "string") setCurrentUser(JSON.parse(storedUser));
  }, []);

  const updateSignupState = (value: object) =>
    setSignUpState((signUpState) => ({ ...signUpState, ...value }));

  const updateCurrentUser = (value: IUser) => {
    if (value)
      secureStorage.storeItem(Constants.currentUser, JSON.stringify(value));
    setCurrentUser((currentUser) => ({ ...currentUser, ...value }));
  };

  const storeTemp = (value: IUser) => {
    if (value) secureStorage.storeItem(Constants.temp, JSON.stringify(value));
    setCurrentUser((temp) => ({ ...temp, ...value }));
  };

  const removeLoggedOutUser = () => {
    secureStorage.removeItem(Constants.currentUser);
  };

  const removeTemp = () => {
    secureStorage.removeItem(Constants.temp);
  };

  const removeSelectedProfile = () => {
    secureStorage.removeItem("selectedProfile");
  };

  const refreshUser = async () => {
    const res = await userAction.getDetails();
    if (res && res.success) updateCurrentUser(res.data);
  };

  return (
    <UserProvider
      value={{
        signUpState,
        updateSignupState,
        currentUser,
        updateCurrentUser,
        removeLoggedOutUser,
        storeTemp,
        temp,
        removeTemp,
        removeSelectedProfile,
        refreshUser,
      }}
    >
      {children}
    </UserProvider>
  );
};

export default UserContext;
