import { getList, put, deleteReq } from "../service/api";
import { SubscribeRequest } from "../../types/requests/subscribe";
import { IResponse } from "../../types/responses";
import { DataResult } from "../../types/data-result";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  plans: async () => await getList({ url: "plans" }),
  history: async (page: string, size: string) =>
    await getList({ url: "payments", filters: { page, size } }),
  cards: async () => await getList({ url: "cards" }),
  subscribe: async (data: SubscribeRequest) =>
    await put({
      url: `plans/${data.plan_id}/subscribe`,
      data: { card_id: data.card_id, frequency: data.frequency },
    }),
  cancel: async (id: number) => await deleteReq({ url: "plans", id }),
};
