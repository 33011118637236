export const Constants = {
  token: "Token",
  type: "Type",
  currentUser: "currentUser",
  temp: "temp",
};

export const Page = {
  dashboard: "/app",
  verify: "/auth/validation",
  setup: "/auth/setup",
  signup: "/auth/signup",
};

export const URL = process.env.REACT_APP_ENV === "prod" ? "https://api.dyeka.ng" : "https://api.dyeka.ng";
