import {
  ChevronLeft,
  ChevronRight,
  Loading,
  Logo,
  NavClass,
  NavCourses,
  NavHome,
  NavLiveStream,
} from "assets/svg";
import appstore from "assets/img/appstore.png";
import googleplay from "assets/img/googleplay.png";
import { Nav } from "components";
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./main.module.scss";
import MainLayoutContext from "../../../context/main-layout";
import { notificationAction, userAction } from "../../../core/actions";
import UserContext from "../../../context/user";
import { toast } from "react-toastify";

type Props = {
  children?: JSX.Element | JSX.Element[];
};

const AppLayout = ({ children }: Props) => {
  const { loading, updateNotifications, setSettings } =
    useContext(MainLayoutContext);
  const { currentUser } = useContext(UserContext);
  const [collapse, setCollapse] = useState(false);
  const [toggle, setToggle] = useState(false);
  const data = [
    {
      icon: <NavHome />,
      name: "Home",
      link: "/app/home",
      disable: false,
    },
    {
      icon: <NavClass />,
      name: "My Class",
      link: "/app/my-class",
      disable: false,
    },
    {
      icon: <NavCourses />,
      name: "My Subjects",
      link: "/app/my-courses",
      disable: false,
    },
    {
      icon: <NavLiveStream />,
      name: "Live streaming",
      link: "/app/live-streaming",
      disable: false,
    },
  ];

  const fetchNotifications = async () => {
    const res = await notificationAction.get();
    if (res) {
      if (res.success) updateNotifications(res.data.result);
      else toast.error(res.message);
    }
  };

  const fetchSettings = async () => {
    const res = await userAction.getSettings();
    if (res) {
      if (res.success) setSettings(res.data);
      else toast.error(res.message);
    }
  };

  useEffect(() => {
    console.log(loading);
  }, [loading]);

  useEffect(() => {
    fetchNotifications().then().catch();
    fetchSettings().then().catch();
  }, [currentUser]);

  return (
    <div
      className={[
        styles.layout,
        "vh-100",
        collapse ? styles.collapse : styles.expand,
      ].join(" ")}
    >
      <aside
        className={[
          toggle ? styles.showMobileNav : styles.hideMobileNav,
          "bg-light",
        ].join(" ")}
      >
        <div className={["text-right", styles.chevron].join(" ")}>
          {!collapse ? (
            <ChevronRight
              className="cursor"
              onClick={() => setCollapse(!collapse)}
            />
          ) : (
            <ChevronLeft
              className="cursor"
              onClick={() => setCollapse(!collapse)}
            />
          )}
        </div>
        <div
          className={["text-right", styles.close].join(" ")}
          onClick={() => setToggle(!toggle)}
        >
          close
        </div>
        {!collapse ? <Logo /> : <h3 className="text-primary-color">dyeka</h3>}
        <ul>
          {data.map((item, index) => (
            <li key={index} className={`${item.disable ? "disabled" : ""}`}>
              <NavLink
                to={!item.disable ? item.link : ""}
                exact
                className={`flex ${item.disable ? "disabled" : ""}`}
                onClick={() => setToggle(!toggle)}
              >
                {item.icon}
                {!collapse && <span>{item.name.toUpperCase()}</span>}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className={styles.app_download}>
          <p className={styles.store}>
            Learn <span>on the go!</span> Anywhere, Anytime
          </p>
          <p className={styles.store_desc}>Download Dyeka mobile app</p>
          <div className={styles.store_img}>
            <div>
              <a
                href="https://apps.apple.com/ng/app/dyeka/id1586528135"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Apple store"
                  src={appstore}
                  width="129px"
                  height="49px"
                />
              </a>
            </div>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.dyekamobile"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Play store"
                  src={googleplay}
                  width="129px"
                  height="49px"
                />
              </a>
            </div>
          </div>
        </div>
      </aside>
      <div className={styles.screen}>
        <Nav onClick={() => setToggle(!toggle)} />
        <main
          className={`${
            !window.location.pathname.includes("/app/settings") &&
            styles.mainPadding
          } main-layout`}
        >
          {children}
          {loading && (
            <div className="loading-cover">
              <Loading />
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default AppLayout;
