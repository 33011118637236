import {IResponse} from "../../types/responses";
import {deleteReq, get, patch, put} from "../service/api";
import {ResetPasswordRequest} from "../../types/requests/reset-password";

export default {
  sendOtp: async (email: string): Promise<IResponse | null> => await patch({ url: 'auth/password', data: { email } }),
  resetPassword: async (data: ResetPasswordRequest): Promise<IResponse | null> => await put({ url: 'auth/password', data }),
  getDetails: async (): Promise<IResponse | null> => await get({ url: 'settings/supervisor' }),
  getSettings: async (): Promise<IResponse | null> => await get({ url: 'settings' }),
  deleteProfile: async (id: number, data?: any): Promise<IResponse | null> => await deleteReq({ url: 'profiles', id, data }),
}