import React, {
  createContext,
  FC,
  ReactChild,
  useEffect,
  useState,
} from 'react';
import { TagResultData, } from '../types/tag';
import { ClassResultData } from 'types/class';

const initialState = {
  updateTags: (arr: TagResultData[]) => { },
  tags: [] as TagResultData[],
  classes: [] as ClassResultData[],
  updateClasses: (arr: ClassResultData[]) => { }
};

const StoreContext = createContext(initialState);
export const StoreContextProvider = StoreContext.Provider;

type Props = {
  children?: ReactChild | ReactChild[];
};

export const StoreProviderContainer: FC<Props> = ({ children }) => {
  const [tags, setTags] = useState<TagResultData[]>(initialState.tags);
  const [classes, setClasses] = useState<ClassResultData[]>(initialState.classes);

  useEffect(() => {

  }, []);


  const updateTags = (arr: TagResultData[]) => {
    if (arr) {
      setTags(arr)
    }
  };

  const updateClasses = (arr: ClassResultData[]) => {
    if (arr) {
      setClasses(arr)
    }
  };

  return (
    <StoreContextProvider
      value={{
        updateTags,
        updateClasses,
        classes,
        tags,
      }}
    >
      {children}
    </StoreContextProvider>
  );
};

export default StoreContext;
