import React, {
  createContext,
  FC,
  ReactChild,
  useEffect,
  useState,
} from "react";
import { IHeading } from "types/main-layout";
import { Notification } from "../types/notification";
import { SettingsData } from "../types/settings";
import { SubjectData } from "../types/subject";
import { SecureStorage } from "core/utils/storage";
import { lessonAction, subjectAction } from "../core/actions";
import { toast } from "react-toastify";
import slugify from "slugify";

const initState = {
  headings: {} as IHeading,
  mainPadding: true,
  updateHeading: (value: IHeading) => {},
  updateMainPadding: (value: boolean) => {},
  loading: false,
  setLoading: (loading: boolean) => {},
  notifications: [] as Notification[],
  updateNotifications: (data: Notification[]) => {},
  settings: {
    biometrics_login: false,
    email_notification: false,
    in_app_notification: false,
  } as SettingsData,
  setSettings: (data: SettingsData) => {},
  subjects: {} as { [key: string]: SubjectData },
  setSubjects: (data: { [key: string]: any }) => {},
};

const MainLayoutContext = createContext(initState);
export const MainLayoutProvider = MainLayoutContext.Provider;

type Props = {
  children?: ReactChild | ReactChild[];
};

export const MainLayoutProviderContainer: FC<Props> = ({ children }) => {
  const [headings, setHeadings] = useState<IHeading>(initState.headings);
  const [mainPadding, setMainPadding] = useState<boolean>(
    initState.mainPadding
  );
  const [subjects, setSubjects] = useState(initState.subjects);
  const [notifications, setNotifications] = useState(initState.notifications);
  const [loading, setLoading] = useState<boolean>(initState.loading);
  const [settings, setSettings] = useState(initState.settings);

  const updateHeading = (value: IHeading) => setHeadings(value);
  const updateMainPadding = (value: boolean) => setMainPadding(value);
  const updateNotifications = (data: Notification[]) => {
    setNotifications(data.splice(0, 40));
  };

  return (
    <MainLayoutProvider
      value={{
        headings,
        mainPadding,
        updateHeading,
        updateMainPadding,
        loading,
        setLoading,
        notifications,
        updateNotifications,
        settings,
        setSettings,
        subjects,
        setSubjects,
      }}
    >
      {children}
    </MainLayoutProvider>
  );
};
export default MainLayoutContext;
