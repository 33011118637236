import { Play } from "assets/svg";
import { FC } from "react";
import "./course.scss";

type Props = {
  time?: string;
  img?: string;
  title: string;
  tutor: string;
  description?: string;
  subject?: string;
  category: string;
  date?: string;
  PlayClick?: () => void;
};

const Course: FC<Props> = ({
  time,
  img,
  title,
  tutor,
  description,
  subject,
  category,
  date,
  PlayClick,
}) => {
  return (
    <div className="course-card">
      <div className="img ratio ratio-16x9">
        <img src={img} alt="lesson-thumbnail" />
        <div className="playIcon">
          <Play
            style={{ height: "100%", width: "100%", cursor: "pointer" }}
            onClick={PlayClick}
          />
        </div>
      </div>
      <div className="details">
        <p className="text-primary-color title">{title}</p>
        <p className="description">{description}</p>
        <div className="d-flex">
          <p className="text-primary-color tutor-name">{tutor}</p>
        </div>
      </div>
    </div>
  );
};
export default Course;
