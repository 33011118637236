import { AppRouter } from "components";
import { AuthProviderContainer } from "context/auth";
import { StoreProviderContainer } from "context/main";
import { UserProviderContainer } from "context/user";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IUser } from "types/user";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getPerformance } from "firebase/performance";

// @ts-ignore
import FreshChat from "react-freshchat";
import "react-toastify/dist/ReactToastify.css";

function getUser() {
  const result = {
    email: "",
    firstName: "Anonymous",
    lastName: "",
    externalId: "",
    phone: "",
  };
  let user = localStorage.getItem("currentUser");
  if (user) {
    const currentUser: IUser = JSON.parse(user);
    result.email = currentUser.email;
    result.firstName = currentUser.first_name;
    result.lastName = currentUser.last_name;
    result.externalId = currentUser.email;
    result.phone = currentUser.phone_number;
  }
  return result;
}

// const firebaseConfig = {
//   apiKey: "AIzaSyATllzzD3STUeZ7a-vIzGyYrZ1Ec_vF_WU",
//   authDomain: "dyeka-a2166.firebaseapp.com",
//   projectId: "dyeka-a2166",
//   storageBucket: "dyeka-a2166.appspot.com",
//   messagingSenderId: "627411105569",
//   appId: "1:627411105569:web:b4ecb30fe4356169183060",
//   measurementId: "G-9BZ0KTLP3T",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const perf = getPerformance(app);

function App() {
  const queryClient = new QueryClient();
  const currentUser = getUser();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProviderContainer>
        <UserProviderContainer>
          <StoreProviderContainer>
            <BrowserRouter>
              <>
                {" "}
                <AppRouter />
                <ToastContainer
                  style={{ width: "500px" }}
                  limit={1}
                  hideProgressBar
                  autoClose={5000}
                />
                <FreshChat
                  token="d5a4a9ca-b866-4485-8b2a-2387cea85d10"
                  email={currentUser.email}
                  first_name={currentUser.firstName}
                  onInit={(widget: any) => {
                    widget.user.setProperties({
                      email: currentUser.email,
                      first_name: currentUser.firstName,
                      last_name: currentUser.lastName,
                      phone: currentUser.phone,
                    });
                  }}
                />
              </>
            </BrowserRouter>
          </StoreProviderContainer>
        </UserProviderContainer>
      </AuthProviderContainer>
    </QueryClientProvider>
  );
}

export default App;
