import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { BottomVector, Logo, TopVector } from '../../../assets/svg';
import styles from './auth.module.scss';

type Props = {
  welcomeText: string;
  intro: string;
  formDescription: string;
  illustration: any; //TODO: change to svg datatype
  navigationLabel?: string;
  navigateTo?: string;
  navigationName?: string;
  logoSideText: string;
  color?: 'primary-color' | 'red';
  children?: JSX.Element | JSX.Element[];
};

const AuthLayout: FC<Props> = ({
  children,
  welcomeText,
  intro,
  illustration: Illustration,
  formDescription,
  navigationLabel,
  navigationName,
  navigateTo,
  logoSideText,
  color = 'primary-color',
}) => {
  return (
    <div className={styles.layout}>
      <aside className={styles.aside}>
        <div
          className={`bg-${color} vh-100 sticky-top flex column align-items-start`}
        >
          <div className="pr-30 pl-30 pt-100">
            <div className={styles.vector}>
              <TopVector />
              <BottomVector />
            </div>
            <p className="text-light opacity-5">{welcomeText}</p>
            <h2 className="text-light mb-40">{intro}</h2>
          </div>
          <div className="flex justify-content-center w-100">
            <Illustration className="w-100" />
          </div>
        </div>
      </aside>
      <div className="bg-green strip sticky-top"></div>
      <div className="bg-yellow-color strip sticky-top"></div>
      <main className="pr-35 pt-35 pl-35 pb-35">
        <p className="text-right mt-0">
          {navigationLabel}{' '}
          {navigateTo && (
            <Link to={navigateTo} className="text-primary-color">
              {navigationName}
            </Link>
          )}
        </p>
        <div className="mt-50">
          <div className={styles.formContainer}>
            <div className="flex align-items-center">
              <b className="mt-40 mr-15">{logoSideText}</b> <Logo />
            </div>
            <p className=" mb-30">{formDescription}</p>
            {children}
          </div>
        </div>
      </main>
    </div>
  );
};

export default AuthLayout;
