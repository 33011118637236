import { AxiosResponse } from "axios";
import { GetTagsApiService } from "core/service/tags";
import { Constants } from "core/utils/constants";
import { handleError } from "core/utils/error-handler";
import { SecureStorage } from "core/utils/storage";
import React, { FC, useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { ITag } from "types/tag";
import { IResponse } from "types/responses";
import MainContext from "context/main";
import { GetClassesApiService } from "core/service/classes";
import { IClass } from "types/class";

interface PrivateRouteProps extends Omit<RouteProps, "component"> {
  component: React.ElementType;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  ...args
}) => {
  const secureStorage = new SecureStorage();
  const isAuthenticated = secureStorage.getItem(Constants.token);
  const isCurrentUser = secureStorage.getItem(Constants.currentUser);
  const isTemp = secureStorage.getItem(Constants.temp);
  const { updateTags, updateClasses } = useContext(MainContext);

  // const profiles = useQuery('getProfiles', GetProfilesApiService, {
  //   onSuccess: (res: AxiosResponse<IResponse<IProfiles>>) => {
  //     const { success, data } = res.data;
  //     return data;
  //   },
  //   onError: (error) => {
  //     const { response } = handleError(error);
  //     alert(response.error);
  //   },
  // });

  const { mutate } = useMutation(GetTagsApiService, {
    onSuccess: (res: AxiosResponse<IResponse<ITag>>) => {
      const { success, data } = res.data;
      if (success) {
        updateTags(data.result);
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      alert(response?.message);
    },
  });

  const classMutate = useMutation(GetClassesApiService, {
    onSuccess: (res: AxiosResponse<IResponse<IClass>>) => {
      const { success, data } = res.data;
      if (success) {
        updateClasses(data.result);
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      alert(response?.message);
    },
  });

  useEffect(() => {
    mutate();
    classMutate.mutate();
  }, []);

  const auth = () => {
    if (isAuthenticated && isCurrentUser) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Route
      {...args}
      render={(props) =>
        auth() ? (
          <Component {...props} />
        ) : isAuthenticated && isTemp ? (
          <Redirect to="/auth/onboarding" />
        ) : (
          <Redirect to="/auth" />
        )
      }
    />
  );
};
export default PrivateRoute;
