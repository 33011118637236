import ProgressBar from "components/progress-bar";
import { Play } from "assets/svg";
import React, { FC } from "react";

type Props = {
  percentage: number;
  img?: string;
  description?: string;
  subject?: string;
  title: string;
  tutor: string;
  PlayClick?: () => void;
};

const UngoingCourse: FC<Props> = ({
  percentage,
  img,
  title,
  tutor,
  subject,
  PlayClick,
}) => {
  return (
    <div className="course-card">
      <div className="img ratio ratio-16x9">
        <img src={img} alt="lesson-thumbnail" />
        <div className="playIcon">
          <Play
            style={{ height: "100%", width: "100%", cursor: "pointer" }}
            onClick={PlayClick}
          />
        </div>
      </div>
      <div className="details">
        <p className="text-primary-color title">{title}</p>
        <ProgressBar percentage={percentage} />
        <div className="d-flex">
          <p className="text-primary-color tutor-name">{tutor}</p>
          <p className="text-primary-color tutor-name">{percentage}%</p>
        </div>
      </div>
    </div>
  );
};
export default UngoingCourse;
